exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cookiepolicy-jsx": () => import("./../../../src/pages/cookiepolicy.jsx" /* webpackChunkName: "component---src-pages-cookiepolicy-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-old-jsx": () => import("./../../../src/pages/index-old.jsx" /* webpackChunkName: "component---src-pages-index-old-jsx" */),
  "component---src-pages-nx-engineering-manager-jsx": () => import("./../../../src/pages/nx-engineering-manager.jsx" /* webpackChunkName: "component---src-pages-nx-engineering-manager-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-services-engineering-manager-training-jsx": () => import("./../../../src/pages/services/engineering-manager-training.jsx" /* webpackChunkName: "component---src-pages-services-engineering-manager-training-jsx" */),
  "component---src-pages-services-fractional-technology-leadership-jsx": () => import("./../../../src/pages/services/fractional-technology-leadership.jsx" /* webpackChunkName: "component---src-pages-services-fractional-technology-leadership-jsx" */),
  "component---src-pages-services-workshops-jsx": () => import("./../../../src/pages/services/workshops.jsx" /* webpackChunkName: "component---src-pages-services-workshops-jsx" */),
  "component---src-pages-surveys-index-jsx": () => import("./../../../src/pages/surveys/index.jsx" /* webpackChunkName: "component---src-pages-surveys-index-jsx" */),
  "component---src-pages-surveys-terms-seasond-drone-giveaway-jsx": () => import("./../../../src/pages/surveys/terms-seasond-drone-giveaway.jsx" /* webpackChunkName: "component---src-pages-surveys-terms-seasond-drone-giveaway-jsx" */),
  "component---src-pages-termsandconditions-jsx": () => import("./../../../src/pages/termsandconditions.jsx" /* webpackChunkName: "component---src-pages-termsandconditions-jsx" */)
}

